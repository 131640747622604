<template>
    <div class="phi-icon-image" :style="styles"></div>
</template>

<script>
export default {
    name: 'phi-icon-image',

    props: {
        fullValue: {
            type: String,
            required: true
        },

        size: {
            type: String,
            required: true
        }
    },

    computed: {
        styles() {
            let size = parseInt(this.size) + 6;
            let src = this.fullValue;

            return {
                display: 'inline-block',

                width: `${size}px`,
                height: `${size}px`,
                fontSize: Math.round(size * .4) + 'px',
                backgroundImage: `url('${src}')`,
                backgroundSize: 'cover',
                borderRadius: '4px'
            };
        }
    }
}
</script>